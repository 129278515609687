.Clients {
  margin: 50px 0px;
  margin-top: 0px;
  padding: 40px 0px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-image: linear-gradient(to right, white, #9595b2) 1;
  background-color: var(--blue-primary);
}
.Clients .swiper-slide img {
  width: 150px;
  height: 50px;
  object-fit: contain;
}
.Clients .swiper-wrapper {
  justify-content: space-between;
}
/* .Clients .swiper-slide {
  width: auto !important;
} */
@media (max-width: 575.98px) {
  .Clients {
    padding: 20px 0px;
  }
}
