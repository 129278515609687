.Banner {
  position: relative;
  height: 75vh;
  padding-top: 160px;
}

.Banner .brand-content {
  width: 500px;
  text-align: left;
}

.Banner .brand-content .banner-heading {
  font-size: 58px;
  line-height: 68px;
  font-weight: 700;
  font-family: "Scandla-Bold";
  background: -webkit-linear-gradient(to right, white 0%, #9595b2 100%);
  background: linear-gradient(to right, white 0%, #9595b2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.Banner .brand-content .banner-text {
  font-family: "Inter Tight";
  font-weight: 700;
  font-size: 21px;
  line-height: 26.04px;
}

.Banner .brand-content .button-block {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 30px;
}

.Banner .brand-content .button-block .book-demo-button {
  font-family: "Inter Tight";
  font-weight: 700;
  font-size: 16px;
  line-height: 21.78px;
  color: var(--black-primary);
  border-radius: 100px;
  padding: 6px 24px;
  text-decoration: none;
}
.Banner .brand-content .button-block .learn-more-button {
  font-family: "Inter Tight";
  font-weight: 700;
  font-size: 16px;
  line-height: 21.78px;
  color: var(--white-primary);
  border-radius: 100px;
  padding: 6px 24px;
  text-decoration: none;
}

.Banner .gif-container {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: -2;
  height: 100%;
}

.Banner .gif-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  mix-blend-mode: multiply;
}

@media (max-width: 1024px) {
  .Banner .brand-content {
    margin: auto;
  }
}

@media (max-width: 575.98px) {
  .Banner {
    height: fit-content !important;
  }
  .Banner .brand-content {
    width: 100%;
  }
  .Banner .gif-container {
    position: unset;
    display: block;
    height: fit-content !important;
    z-index: 1;
    margin-top: 20px;
  }
  .Banner .gif-container img {
    width: 100%;
    float: none;
    object-fit: cover;
  }
  .Banner .brand-content .banner-heading {
    font-size: 38px;
    line-height: 45.6px;
  }

  .Banner .brand-content .banner-text {
    font-size: 20px;
    line-height: 24.2px;
  }
  .Banner .brand-content .button-block .book-demo-button {
    font-size: 16px;
    line-height: 19.36px;
  }
  .Banner .brand-content .button-block .learn-more-button {
    font-size: 16px;
    line-height: 19.36px;
  }

  .Banner {
    /* background: none; */
    padding-top: 120px;
  }
}
