@import "swiper/css";
@import "swiper/css/pagination";
@import "swiper/css/navigation";

.MembershipPlan {
  background-color: var(--blue-block-background);
  padding: 90px 0px;
  text-align: left;
}

.MembershipPlan .customContainer {
  padding: 0px 20px;
}

.MembershipPlan .head-content .heading-container {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
}

.MembershipPlan .head-content .heading-container h3 {
  font-size: 40px;
  line-height: 68px;
  font-weight: 700;
  font-family: "Scandla-Bold";
  font-weight: 700;
  background: -webkit-linear-gradient(to right, white 0%, #9595b2 100%);
  background: linear-gradient(to right, white 0%, #9595b2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0;
  padding: 0;
}

.MembershipPlan .head-content p {
  color: #fff;
  font-family: "Inter Tight";
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 40px;
}
.MembershipPlan .card-outer {
  height: 100%;
}
.MembershipPlan .card-outer .card-inner {
  padding: 10px 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.MembershipPlan .card-outer .card-inner .card-brand {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  gap: 5px;
  border-bottom: 1px solid #fff !important;
  margin-bottom: 40px;
}

.MembershipPlan .card-outer .card-inner .mainHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.MembershipPlan .card-outer .card-inner .title {
  font-size: 28px;
  line-height: 35.4px;
  font-family: "Scandla-Bold";
  font-weight: 700;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 15px;
}

.MembershipPlan .card-outer .card-inner .type {
  padding: 3px 10px;
  border-radius: 25px;
  font-size: 12px;
  line-height: 20px;
  color: var(--black-primary);
  background-color: #fff;
  font-family: "Scandla-Bold";
  font-weight: 700;
  text-align: center;
}

.MembershipPlan .card-outer .card-inner .description {
  font-size: 13px;
  line-height: 15px;
  font-family: "Inter Tight";
  font-weight: 500;
}

.MembershipPlan .card-outer .card-inner .price-container {
  position: relative;
  padding-left: 20px;
}
.MembershipPlan .card-outer .card-inner .price-container .currency {
  font-size: 28px;
  line-height: 40.2px;
  font-family: "Scandla-Bold";
  font-weight: 700;
  position: absolute;
  top: 16px;
  left: 0;
}

.MembershipPlan .card-outer .card-inner .price-container .price {
  font-size: 60px;
  line-height: 115.2px;
  font-family: "Scandla-Bold";
  font-weight: 700;
}

.MembershipPlan .card-outer .card-inner .price-container .period {
  font-size: 18px;
  line-height: 24px;
  font-family: "Inter Tight";
  font-weight: 500;
}

.MembershipPlan .card-outer .card-inner .card-bottom .single-item {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  margin-top: 6px;
}

.MembershipPlan .card-outer .card-inner .card-bottom .single-item .title {
  font-size: 16px;
  line-height: 20px;
  font-family: "Inter Tight";
  font-weight: 400;
}

.MembershipPlan .card-outer .card-inner .button-container {
  margin-top: 30px;
  text-align: center;
}
.MembershipPlan .card-outer .card-inner .get-started-button {
  font-family: "Inter Tight";
  font-weight: 700;
  font-size: 15px;
  line-height: 21.78px;
  color: var(--black-primary);
  border-radius: 100px;
  padding: 8px 24px;
  width: 100%;
  display: block;
  text-decoration: none;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  content: "" !important;
}
.swiper-button-next,
.swiper-button-prev {
  position: unset;
}

.MembershipPlan .popular .get-started-button {
  background-color: #fff !important;
}

.MembershipPlan .popular .global-block-wrapper_top {
  background-color: none;
}

.MembershipPlan .popular .global-block_top {
  background-color: var(--green-primary);
  color: #000 !important;
}

.MembershipPlan .popular .card-outer .card-inner .card-brand {
  border-bottom: 1px solid #000 !important;
}

@media (max-width: 575.98px) {
  .MembershipPlan .head-content .heading-container {
    align-items: start;
  }
  .MembershipPlan .card-outer .card-inner .title {
    font-size: 24px;
    line-height: 35.4px;
  }
  .MembershipPlan .card-outer .card-inner .card-bottom .single-item .title {
    font-size: 14px;
    line-height: 16px;
  }
  .MembershipPlan .card-outer .card-inner .get-started-button {
    font-size: 12px;
    padding: 5px 24px;
  }
  .MembershipPlan .head-content .heading-container h3 {
    font-size: 32px;
    line-height: 32px;
  }
  .MembershipPlan .card-outer .card-inner {
    padding: 10px 20px;
  }
  .MembershipPlan .head-content p {
    font-size: 16px;
    line-height: 19.36px;
  }
}

@media (min-width: 1800px) {
  .MembershipPlan .customContainer {
    padding-left: 20%;
  }
}

@media (min-width: 1650px) and (max-width: 1800px) {
  .MembershipPlan .customContainer {
    padding-left: 18%;
  }
}

@media (min-width: 1500px) and (max-width: 1650px) {
  .MembershipPlan .customContainer {
    padding-left: 16%;
  }
}

@media (min-width: 1350px) and (max-width: 1500px) {
  .MembershipPlan .customContainer {
    padding-left: 12%;
  }
}

@media (min-width: 1200px) and (max-width: 1350px) {
  .MembershipPlan .customContainer {
    padding-left: 8%;
  }
}

/* //////////  info block //////////////// */

.info-block.member {
  display: block;
}

.info-block.member a {
  font-family: "Roboto Slab", serif;
  text-decoration: none;
  /* border: 1px solid #fff; */
  border-radius: 100px;
  width: 21px;
  height: 21px;
  display: block;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
}

.info-block.member a span {
  color: #fff;
  font-size: 15px;
  font-family: "Inter Tight";
  font-weight: normal;
  line-height: normal;
  vertical-align: middle;
}

/*********************
TOOLTIP CSS
*********************/

/* Tooltip attribute */
.info-block.member [data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Tooltip content hidden by default */
.info-block.member [data-tooltip]:before,
.info-block.member [data-tooltip]:after {
  transition: all 0.5s ease-in-out;
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
}

/* Tooltip position */
.info-block.member [data-tooltip]:before {
  position: absolute;
  bottom: 130%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px 5px;
  width: 160px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  background-color: #ffffff;
  background-color: hsl(0, 0%, 100%, 1);
  color: #5e5d5d;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 13px;
  line-height: normal;
  font-family: "Inter Tight";
  border-radius: 6px;
  font-weight: 500;
}

/* Triangle hack for Tooltip bubble */
.info-block.member [data-tooltip]:after {
  position: absolute;
  bottom: 130%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #ffffff;
  border-top: 5px solid hsl(0, 0%, 100%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Tooltip visible on hover */
.info-block.member [data-tooltip]:hover:before,
.info-block.member [data-tooltip]:hover:after {
  transition: all 0.5s ease-in-out;
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.popular .info-block.member a {
  /* border: 1px solid #000; */
}

.popular .info-block.member a span {
  color: #000;
}

.price-2{
  font-size: 50px !important;
}

.price-3{
  font-size: 45px !important;
}